/* You can add global styles to this file, and also import other style files */
@import '~ngx-toastr/toastr';
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-grids/styles/material.css';
@import "../node_modules/primeicons/primeicons.css";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

html {
  overflow-x: hidden !important;
}
/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}
.top-section {
  display: flex;
  margin-bottom: 50px;
}
.page-title {
  font-size: 32px !important;
  color: var(--main-accent-color);
  flex-grow: 1;
}

.dialog-form-field{
  padding-top: 25px !important;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
.table-header {
  min-width: 180px;
}
.big-table-header {
  min-width: 230px;
}

.float-left{
  float: left !important;
}

@media print {
  .not-printed {
    display: none !important;
  }
  .print-table-width {
    width: 100%;
  }
}

.p-selectbutton .p-button.p-highlight{
  background: var(--main-accent-color);
}

.p-button.p-button-icon-only{
  background: var(--main-accent-color);
}

.p-button{
  background: var(--main-accent-color);
}

html, body {
  height: 100%;
  margin: 0;
 }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.full-width{
  width: 100%;
}

.flex-100 {
  flex: 0 0 100%;
}
.flex-50 {
  flex: 0 0 50%;
}

i.fa-action-button {
  color: #000000;
  margin-right: 20px;
  font-size: 22px;
  cursor: pointer;
}

i.fa-red-action-button {
  color: #db0007;
  margin-right: 15px;
  font-size: 18px;
  cursor: pointer;
}

i.fa-green-action-button {
  color: rgb(40, 158, 40);
  margin-right: 15px;
  font-size: 18px;
  cursor: pointer;
}

.input-full-width {
  width: 100%;
}

.btn-secondary {
  background-color: #FFF !important;
  border: 1px solid var(--main-accent-color-light) !important;
  color: var(--main-accent-color-light);
  text-transform: uppercase;
  font-size: 14px;
  padding: 11px;
  min-width: 180px;
}
.btn-secondary:hover {
  background-color: var(--main-accent-color-light) !important;
}
.btn-secondary.active {
  background-color: var(--main-accent-color-light) !important;
  border: none !important;
  color: #FFF;
}

.table {
  color: var(--main-accent-color) !important;
}
.table th {
  font-weight: 400;
}
.table td {
  color: var(--dark);
  font-weight: 100;
}

.filters-section {
  margin-bottom: 20px;
}

.e-pager .e-currentitem {
  background: var(--main-accent-color-light);
}

.spinner-grow {
  position: absolute;
  top: 50%;
}

.mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
  color: var(--main-accent-color-light);
}
.e-input-group.e-ddl .e-ddl-icon {
  color: var(--main-accent-color-light);
}

@media (max-width: 580px) {
  .btn-secondary {
    min-width: auto !important;
  }
}

@media (max-width: 460px) {
  .filters-section {
    width: 100%;
  }
  .btn-secondary {
    font-size: 14px;
  }
}

@media (max-width: 390px) {
  .btn-secondary {
    font-size: 12px;

  }
}





@media (min-width: 576px) {
  .container {
    max-width: 100% !important;
    padding-right: 2% !important;
    padding-left: 2% !important;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 100% !important;
    padding-right: 2% !important;
    padding-left: 2% !important;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 100% !important;
    padding-right: 2% !important;
    padding-left: 2% !important;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px !important;
  }
  .navigation {
    flex: 0 0 85%;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1440px !important;
  }
}






