:root {
  --white: #ffffff;
  --light-white: #efefef;
  --light-grey: #e7e7e7;
  --grey: #B4B4B4;
  --dark-grey: #656565;
  --darker-grey: #484848;
  --dark: #303030;
  --black: #000000;
  --light-turqois: #b7e3d8;
  --green: #aaee87;
  --red: #ff2929;
  --main-accent-color: #0159A1;
  --main-accent-color-light: #2b82d9;
  --main-accent-color-trans: #f4963847;
  --main-accent-hover-color: #ad6c2a;
  --second-accent-color: var(--main-accent-color);
  --background-grey: #f9f9f9;
  --instagram-hover: #C13584;
  --warning-red: rgb(129, 0, 0);
  --swiper-theme-color: #e98c34ff !important;
}
